import React from "react";
import { Button, Modal } from "react-bootstrap";

class CustomConfirm extends React.Component {

    //constructor
    constructor(props) {
        super(props);
    }

    render = () => {

        return (
            <Modal
                show={this.props.show}
                onHide={null}
                backdrop={'static'}
                keyboard={false}
            >
                {this.props.title &&
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body>
                    {this.props.body}
                </Modal.Body>
                <Modal.Footer>
                    <div
                        style={{
                            flexGrow: 1,
                            flexShrink: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            margin: '-3px'
                        }}
                    >
                        <div
                            className={'standard-padding-3 vertical-padding-3'}
                            style={{
                                flex: 1
                            }}
                        >
                            <Button
                                block
                                variant={'dark'}
                                onClick={(event) => {
                                    if (typeof this.props.onCancel === 'function') {
                                        this.props.onCancel(event);
                                    }
                                }}
                            >
                                {this.props.cancelLabel}
                            </Button>
                        </div>
                        {this.props.confirmLabel &&
                            <div
                                className={'standard-padding-3 vertical-padding-3'}
                                style={{
                                    flex: 1
                                }}
                            >
                                <Button
                                    block
                                    variant={this.props.variant}
                                    onClick={(event) => {
                                        if (typeof this.props.onConfirm === 'function') {
                                            this.props.onConfirm(event);
                                        }
                                    }}
                                >
                                    {this.props.confirmLabel}
                                </Button>
                            </div>
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

//export
export default CustomConfirm;