import React from "react";
import ToolboxAction from "../../../Actions/ToolboxAction";
import CustomTranslation from "../../Components/CustomTranslation";
import ToolboxNewParticipant from "./Components/ToolboxNewParticipant";
import ToolboxParticipant from "./Components/ToolboxParticipant";
import Loader from "../../Components/Loader";
import CustomFileInput from "../../Components/CustomFileInput";
import { toast } from "react-hot-toast";
import Uuid from "uuid/v1";
import { Button } from "react-bootstrap";


class RiskAnalysisPreferencesTab extends React.Component {

    constructor(props) {
        super(props);

        //set state
        this.state = {
            participants: [],
            participantElements: null,
            fileUploadKey: Uuid(),
            downloadWaiting: false
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        //get participants
        let participants = await ToolboxAction.getParticipants(this.props.defaultCompany.id);

        //sort participants
        participants.sort((a, b) => {
            return (a.firstName.toLowerCase() + a.lastName.toLowerCase()).localeCompare(b.firstName.toLowerCase() + b.lastName.toLowerCase());
        });

        let participantElements = [];
        for (let participant of participants) {
            participantElements.push(
                <ToolboxParticipant key={participant.id}
                    data={participant}
                    onSubmitted={(participant) => {
                        this.updateElements();
                    }}
                />
            );
        }

        this.setState({
            participantElements: participantElements
        });
    }

    handleUpload = async (file) => {
        let response = await ToolboxAction.uploadParticipants(file);
        this.setState({ fileUploadKey: Uuid() });

        if (!response.error) {
            toast.success(<CustomTranslation value={'KEY.Upload successful'} />);
        } else {
            toast.error(<CustomTranslation value={'KEY.Upload failed'} />);
            return;
        }

        if (response) {
            this.updateElements();
        }
    }

    handleDownload = async () => {
        this.setState({ downloadWaiting: true });
        try {
            await ToolboxAction.downloadParticipants();
        } catch (e) {
            toast.error(<CustomTranslation value={'KEY.Download failed'} />);
        }
        this.setState({ downloadWaiting: false });
    }

    render = () => {
        if (!this.state.participantElements) {
            return <Loader />;
        }
        return (
            <div style={this.props.style} className={this.props.className} key={this.props.key}>
                {/* Title */}
                <div style={{ marginTop: 0 }}>
                    <h2 className={'text-left'}><CustomTranslation value={'KEY.Toolbox Participants'} /></h2>
                </div>

                {/* Content */}
                <div className={'vertical-margin-2'} style={{ marginTop: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', gap: '1rem' }}>
                        <div style={{ flexGrow: 1, flexShrink: 1 }}>
                            <CustomFileInput
                                key={this.state.fileUploadKey}
                                style={{ marginTop: 0 }}
                                btnLabel={
                                    <CustomTranslation value={'KEY.Upload'} />
                                }
                                mimeType={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                onUpload={(val) => this.handleUpload(val)}
                                noUpload={true}
                            />
                        </div>
                        <div style={{ flexGrow: 1, flexShrink: 1 }}>
                            <Button
                                block
                                variant={'dark'}
                                disabled={this.state.downloadWaiting}
                                onClick={() => {
                                    this.handleDownload();
                                }}
                            >
                                <CustomTranslation value={'KEY.Download'} />
                            </Button>
                        </div>
                    </div>
                    <div className={'vertical-margin-2'}>
                        <ToolboxNewParticipant
                            onSubmitted={(participant) => {
                                this.updateElements();
                            }}
                        />
                    </div>
                    <div>
                        {this.state.participantElements}
                    </div>
                </div>
            </div>
        );
    };
}

export default RiskAnalysisPreferencesTab;