import React from "react";
import CustomTranslation from "../../../Components/CustomTranslation";
import ToolboxAction from "../../../../Actions/ToolboxAction";
import ToolboxParticipantEditModal from "./ToolboxParticipantEditModal";
import { toast } from "react-hot-toast";
import Uuid from "uuid/v1";
import CustomBorderedButton from "../../../Components/CustomBorderedButton";

class ToolboxNewParticipant extends React.Component {

    constructor(props) {
        super(props);

        //set state
        this.state = {
            showModal: false,
            modalKey: Uuid()
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {
    }

    newParticipant = async (firstName, lastName) => {
        let data = {
            firstName: firstName,
            lastName: lastName,
            active: true
        };

        let participant = await ToolboxAction.createParticipant(data);

        if (typeof this.props.onSubmitted === 'function') {
            this.props.onSubmitted(participant);
        }

        return participant;
    }

    render = () => {
        return (
            <div style={this.props.style} className={this.props.className} key={this.props.key}>
                {/* Content */}
                <CustomBorderedButton
                    title={<CustomTranslation value={'KEY.Add Participant'} />}
                    onClick={() => {
                        this.setState({ showModal: true });
                    }}
                />

                <ToolboxParticipantEditModal
                    key={this.state.modalKey}
                    title={<CustomTranslation value={'KEY.Add Participant'} />}
                    show={this.state.showModal}
                    onClose={() => {
                        this.setState({
                            showModal: false
                        });
                    }}
                    onConfirm={({firstName, lastName}) => {
                        this.newParticipant(firstName, lastName).then(() => {
                            this.setState({
                                modalKey: Uuid(),
                            });
                            toast.success(<CustomTranslation value="KEY.Participant added!" />);
                        });
                        this.setState({
                            showModal: false
                        });
                    }}
                    confirmLabel={<CustomTranslation value={'KEY.Add'} />}
                />
            </div>
        );
    };
}

export default ToolboxNewParticipant;