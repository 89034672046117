import React from "react";
import { Button, Spinner } from "react-bootstrap";
import APIAction from "../../Actions/APIAction";
import { toast } from "react-hot-toast";
import CustomTranslation from "./CustomTranslation";

class CustomFileInput extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            isLoaded: false,
            spinner: this.props.fileId ? true : false,
            fileId: this.props.fileId ?? null
        }
    }

    //on update
    componentDidUpdate(prevProps) {
        //check that screen is newly focused
        if (!this.state.isLoaded) {
            this.updateElements();
        }
    }

    //on inital
    componentDidMount() {
        //update elements
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        // get current file
        if (this.props.fileId) {

            //get file
            let file = await APIAction.request('/files/' + this.props.fileId, 'GET', true);

            //append image data to state
            this.setState(
                {
                    fileIn: file.originalFileName,
                    spinner: false,
                    isLoaded: true
                }
            );
        }
    }

    //upload file
    uploadFile = async (file) => {

        //check if file is valid
        let mimetypes = this.props.mimeType;
        if (typeof mimetypes === 'function') mimetypes = mimetypes();
        mimetypes = mimetypes.split(',');

        //check type
        if (this.props.mimeType && mimetypes.includes(file.type)) {

            //set spinner
            this.setState({ spinner: true });

            if (!this.props.noUpload) {

                //create form data
                let formData = new FormData();
                formData.append('file', file, file.name);

                //send data
                let response = await APIAction.request('/files', 'POST', true, formData, true, true, 'formdata');

                //if handler is provided
                if (typeof this.props.onUpload === 'function') {
                    this.props.onUpload(response);
                }

            } else {
                //if handler is provided
                if (typeof this.props.onUpload === 'function') {
                    this.props.onUpload(file);
                }
            }

            //set state
            this.setState({
                spinner: false,
                isLoaded: false
            });

        } else {
            toast.error(<CustomTranslation value={'KEY.File could not be uploaded.'} />);
        }
    }

    render = () => {
        return (
            <div className={this.props.className} style={this.props.style}>
                <div>
                    {/* Label */}
                    <div style={{
                        display: 'flex',
                        flex: 'row',
                        marginBottom: '0.5rem'
                    }}>
                        {this.props.iconName &&
                            <div
                                className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                            >
                                <i className={this.props.iconName + ' icon'} />
                            </div>
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')} style={{ paddingBottom: 0 }}>{this.props.label}{this.props.labelAppend}</span>
                        </div>
                    </div>
                    {/* Input (hidden) */}
                    <div
                        style={{
                            width: 0,
                            height: 0,
                            opacity: 0,
                            position: 'absolute'
                        }}
                    >
                        <input
                            ref={fileInput => this.fileInput = fileInput}
                            type={'file'}
                            accept={typeof this.props.mimeType === 'function' ? this.props.mimeType() : this.props.mimeType}
                            onChange={(event) => {
                                let file = event.target.files[0];
                                this.uploadFile(file);
                            }}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </div>
                    {/* Button */}
                    <Button
                        block
                        variant={'dark'}
                        onClick={(event) => {
                            this.fileInput.click();
                        }}
                        disabled={this.state.spinner}
                    >
                        {this.props.btnLabel}
                        {this.state.spinner &&
                            <span>&nbsp;<Spinner size={'sm'} animation={'border'} variant={'primary'} /></span>
                        }
                    </Button>
                    {this.state.fileIn &&
                        <small>{this.state.fileIn}</small>
                    }
                </div>
            </div>
        );
    }
}

//export
export default CustomFileInput;