import React from "react";
import CustomTranslation from "../../../Components/CustomTranslation";
import CustomInput from "../../../Components/CustomInput";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

class ToolboxParticipantEditModal extends React.Component {

    constructor(props) {
        super(props);

        //set state
        this.state = {
            firstName: props.firstName ?? '',
            lastName: props.lastName ?? ''
        };
    }

    render = () => {
        return (
            <Modal
                show={this.props.show}
                onHide={() => {
                    if (typeof this.props.onHide === 'function') {
                        this.props.onClose();
                    }
                }}
                backdrop={'static'}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className={'vertical-margin-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '-3px',
                            marginRight: '-3px'
                        }}
                    >
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{ flex: 1 }}
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.First Name'} />}
                            value={this.state.firstName}
                            onChangeText={(text) => this.setState({ firstName: text })}
                        />
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{ flex: 1 }}
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Last Name'} />}
                            value={this.state.lastName}
                            onChangeText={(text) => this.setState({ lastName: text })}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        style={{
                            flexGrow: 1,
                            flexShrink: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            margin: '-3px'
                        }}
                    >
                        <div
                            className={'standard-padding-3 vertical-padding-3'}
                            style={{
                                flex: 1
                            }}
                        >
                            <Button
                                block
                                variant={'dark'}
                                onClick={() => {
                                    if (typeof this.props.onClose === 'function') {
                                        this.props.onClose();
                                    }
                                }}
                            >
                                <CustomTranslation value={'KEY.Close'} />
                            </Button>
                        </div>
                        <div
                            className={'standard-padding-3 vertical-padding-3'}
                            style={{
                                flex: 1
                            }}
                        >
                            <Button
                                block
                                variant={'success'}
                                onClick={(event) => {
                                    if (this.state.firstName === '' || this.state.lastName === '') {
                                        toast.error(<CustomTranslation value={'KEY.Please fill in all fields.'} />);
                                        return;
                                    }

                                    if (typeof this.props.onConfirm === 'function') {
                                        this.props.onConfirm({
                                            firstName: this.state.firstName,
                                            lastName: this.state.lastName
                                        });
                                    }
                                }}
                            >
                                {this.props.confirmLabel}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default ToolboxParticipantEditModal;