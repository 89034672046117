import React from "react";
import ToolboxAction from "../../../Actions/ToolboxAction";
import CustomConfirm from "../../Components/CustomConfirm";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomCheckBox from "../../Components/CustomCheckBox";
import { Spinner } from "react-bootstrap";
import CustomInput from "../../Components/CustomInput";
import Uuid from 'uuid/v1';

class ToolboxParticipantPicker extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        this.changeTimer = null;
        this.selectedParticipants = [];

        //set state
        this.state = {
            addFormKey: Uuid(),
            isLoaded: false,
            participants: [],
            selectedParticipants: [],
            showModal: false
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements(true);
    }

    //update elements
    updateElements = async (setValue = false) => {
        //get participants
        let participants = await ToolboxAction.getParticipants(this.props.company.id);
        participants.sort((a, b) => {
            return (a.firstName.toLowerCase() + a.lastName.toLowerCase()).localeCompare((b.firstName.toLowerCase() + b.lastName.toLowerCase()));
        });

        //set value if provided
        if (setValue && this.props.value && Array.isArray(this.props.value)) {
            this.setState({
                selectedParticipants: this.props.value.sort((a, b) => {
                    return (a.firstName.toLowerCase() + a.lastName.toLowerCase()).localeCompare((b.firstName.toLowerCase() + b.lastName.toLowerCase()));
                })
            });
        }

        this.setState({
            participants: participants,
            isLoaded: true
        });
    }

    //change participants
    changeParticipants = async (participant, value) => {


        let selectedParticipants = this.state.selectedParticipants;
        if (value) {
            if (!selectedParticipants.map(p => p.id).includes(participant.id)) {
                selectedParticipants.push(participant);
            }
        } else {
            selectedParticipants = selectedParticipants.filter((selectedParticipant) => {
                return selectedParticipant.id !== participant.id;
            });
        }

        selectedParticipants.sort((a, b) => {
            return (a.firstName.toLowerCase() + a.lastName.toLowerCase()).localeCompare((b.firstName.toLowerCase() + b.lastName.toLowerCase()));
        });

        if (this.changeTimer) {
            clearTimeout(this.changeTimer);
        }
        this.changeTimer = setTimeout(() => {
            this.setState({ selectedParticipants: selectedParticipants }, () => {
                if (this.props.onValueChange && typeof this.props.onValueChange === 'function') {
                    this.props.onValueChange(selectedParticipants);
                }
                return selectedParticipants
            });
        }, 500);
    }

    //create new participant
    createParticipant = async () => {
        let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            active: true
        };
        let participant = await ToolboxAction.createParticipant(data);
        delete participant['@context'];

        await this.changeParticipants(participant, true);

        await this.updateElements();

        this.setState({
            firstName: '',
            lastName: '',
            addFormKey: Uuid(),
        });
    }

    //render
    render = () => {
        return (
            <div className={this.props.className} style={this.props.style}>
                {/* Label */}
                <div style={{
                    display: 'flex',
                    flex: 'row',
                    marginBottom: '0.5rem'
                }}>
                    {this.props.iconName &&
                        <div
                            className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                        >
                            <i className={this.props.iconName + ' icon'} />
                        </div>
                    }
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexGrow: 1,
                        flexShrink: 1
                    }}>
                        <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')} style={{ paddingBottom: 0 }}>{this.props.label}{this.props.labelAppend}</span>
                    </div>
                    <div>
                        <span
                            className={'icon-container bg-success touchable'}
                            onClick={(event) => {
                                this.setState({ showModal: true });
                            }}
                        >
                            <i className={'las la-plus icon text-white'} />
                        </span>
                    </div>
                </div>
                {/* Input (hidden) */}
                <CustomConfirm
                    title={this.props.title}
                    show={this.state.showModal}
                    onCancel={(event) => {
                        this.setState({ showModal: false });
                    }}
                    body={
                        <>
                            {/* spinner */}
                            {this.state.isLoaded === false &&
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Spinner animation={'border'} variant={'primary'} />
                                </div>
                            }
                            {this.state.participants.map((participant, index) => {
                                return (
                                    <div key={index} className={'vertical-padding-3'}>
                                        <CustomCheckBox
                                            key={this.state.addFormKey + '_participant_' + index}
                                            label={participant.firstName + ' ' + participant.lastName}
                                            onValueChange={(value) => {
                                                this.changeParticipants(participant, value);
                                            }}
                                            value={this.state.selectedParticipants.map(p => p.id).includes(participant.id)}
                                        />
                                    </div>
                                );
                            })}
                            <div
                                className={'bordered-top vertical-margin-2'}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '1rem'
                                }}
                                className={'vertical-padding-3'}
                            >
                                <div style={{ flexGrow: 1, flexShrink: 1 }}>
                                    <CustomInput
                                        key={this.state.addFormKey + '_firstName'}
                                        iconName={'las la-user'}
                                        label={<CustomTranslation value={'KEY.First Name'} />}
                                        value={this.state.firstName}
                                        onChangeText={(value) => {
                                            this.setState({ firstName: value });
                                        }}
                                    />
                                </div>
                                <div style={{ flexGrow: 1, flexShrink: 1 }}>
                                    <CustomInput
                                        key={this.state.addFormKey + '_lastName'}
                                        iconName={'las la-user'}
                                        label={<CustomTranslation value={'KEY.Last Name'} />}
                                        value={this.state.lastName}
                                        onChangeText={(value) => {
                                            this.setState({ lastName: value });
                                        }}

                                    />
                                </div>
                                <div>
                                    <span
                                        className={'icon-container bg-success touchable'}
                                        onClick={(event) => {
                                            if (this.state.firstName && this.state.lastName) {
                                                this.createParticipant({
                                                    firstName: this.state.firstName,
                                                    lastName: this.state.lastName
                                                });
                                            }
                                        }}
                                    >
                                        <i className={'las la-plus icon text-white'} />
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                    cancelLabel={<CustomTranslation value={'KEY.Close'} />}
                />
                {/* Content */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <div
                        className={'bordered-left standard-margin-2'}
                        style={{ marginLeft: 0 }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            flexShrink: 1
                        }}
                    >
                        {this.state.selectedParticipants.map((x, i) => {
                            return (
                                <div
                                    key={i}
                                    className={'vertical-margin-3'}
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <div
                                            style={{
                                                flexGrow: 1,
                                                flexShrink: 1
                                            }}
                                        >
                                            <p style={{ margin: 0 }}>{x.firstName} {x.lastName}</p>
                                        </div>
                                        <div>
                                            <span
                                                className={'icon-container bg-danger touchable standard-margin-2'}
                                                style={{ marginRight: 0 }}
                                                onClick={(event) => {
                                                    this.changeParticipants(x, false);
                                                }}
                                            >
                                                <i className={'las la-minus icon text-white'} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default ToolboxParticipantPicker;