import React from "react";
import UserActions from "../../Actions/UserAction";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import AssignmentAction from "../../Actions/AssignmentAction";
import CustomContainer from "../Components/CustomContainer";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import Project from "../Home/Components/Project";
import { Breadcrumb, Button } from "react-bootstrap";
import { generatePath, useParams } from "react-router-dom";
import CustomTranslation from "../Components/CustomTranslation";
import Loader from "../Components/Loader";
import CustomInput from "../Components/CustomInput";
import CustomCheckBox from "../Components/CustomCheckBox";
import CustomSelect from "../Components/CustomSelect";
import CustomImageInput from "../Components/CustomImageInput";
import CustomDate from "../Components/CustomDate";
import CustomFileInput from "../Components/CustomFileInput";
import CustomListInput from "../Components/CustomListInput";
import { toast } from "react-hot-toast";
import ToolboxAction from "../../Actions/ToolboxAction";
import ToolboxParticipantPicker from "../Toolbox/Components/ToolboxParticipantPicker";

class EditAssignmentItemScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            ed: new Date(),
            finished: false,
            concept: false
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    createObservation = async () => {
        //validate data
        if (!this.state.user) {
            toast.error(<CustomTranslation value={'KEY.Please select a target User.'} />);
            return false;
        }
        //do call
        let response = await AssignmentAction.edit(this.state);

        //if is toolbox, post data
        if (this.state.data.assignment.toolbox) {
            let toolboxData = {
                id: this.state.data.assignment.toolbox.id
            };
            if (this.state.documentApiId) {
                toolboxData.document = this.state.documentApiId;
            }

            // set participants
            let participants = [];
            for (let participant of this.state.participants) {
                participants.push({
                    name: participant,
                    signature: null
                });
            }
            toolboxData.participants = participants;

            // set selected participants
            let selectedParticipants = [];
            for (let participant of this.state.selectedParticipants) {
                selectedParticipants.push(
                    participant.id
                );
            }

            toolboxData['signatures'] = selectedParticipants;

            await ToolboxAction.updateToolbox(toolboxData);
        }

        //navigate
        setTimeout(() => {
            window.location.href = generatePath('/construction_site/:construction_site_id/assignments', { construction_site_id: this.state.data.constructionSite.id });
        }, 1000);
    }

    //handle photo upload
    handleUpload = async (file, name) => {
        let newState = {};
        newState[`${name}Id`] = file.id;
        newState[`${name}ApiId`] = file['@id'];
        this.setState(newState);
    }

    updateElements = async () => {
        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //get assignment
        let assignment = await AssignmentAction.get(this.props.params.assignment_id);

        //get users for construction site
        let users = await UserActions.getUserListConstructionSite(cs.id);

        //define user options
        let userOptions = [];

        //loop
        users.forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            userOptions.push({
                label: label,
                value: element['@id']
            });
        });

        //currently selected user
        let currentUser = userOptions[0];
        if (assignment.sender) {
            userOptions.forEach(element => {
                if (element.value === assignment.sender['@id']) {
                    currentUser = element;
                }
            });
        }
        if (assignment.targetUser) {
            userOptions.forEach(element => {
                if (element.value === assignment.targetUser['@id']) {
                    currentUser = element;
                }
            });
        }

        //set in state
        this.setState({
            data: {
                constructionSite: cs,
                assignment: assignment
            },
            id: assignment.id,
            constructionSite: {
                id: cs['@id']
            },
            userOptions: userOptions,
            user: currentUser,
            targetUser: { 'id': currentUser.value },
            title: assignment.title,
            riskDescription: assignment.riskDescription,
            recommendation: assignment.recommendation,
            immediateActionTaken: assignment.immediateActionTaken,
            dateRequested: (new Date(assignment.dateRequested)),
            finished: assignment.finished,
            concept: assignment.concept,
            imageId: assignment.photo ? assignment.photo.id : null,
            image2Id: assignment.altPhoto ? assignment.altPhoto.id : null,
            documentId: assignment.toolbox?.document?.id,
            participants: assignment.toolbox?.participants?.map(p => p.name) || [],
            selectedParticipants: assignment.toolbox?.signatures?.map(s => s.toolboxParticipant) || []
        });
    }

    render = () => {
        if (this.state && this.state.data) {

            //return
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            disabled={
                                (this.state.data.assignment.toolbox ?
                                    (
                                        !(this.state.concept) &&
                                        !(this.state.user.value && this.state.title && this.state.dateRequested && ((this.state.participants && this.state.participants.length > 0) || (this.state.selectedParticipants && this.state.selectedParticipants.length > 0)) &&
                                            (this.state.documentId /*|| this.state.image2Id*/)
                                        )
                                    ) :
                                    ((this.state.data.assignment.observation && this.state.data.assignment.observation.positiveRemark) ?
                                        (
                                            !(this.state.concept) &&
                                            !(this.state.riskDescription && this.state.title)
                                        ) :
                                        (
                                            !(this.state.concept) &&
                                            !(this.state.riskDescription && this.state.immediateActionTaken && this.state.targetUser.id && this.state.title && this.state.dateRequested && this.state.recommendation)
                                        ))
                                )
                            }
                            onClick={() => {
                                this.createObservation();
                            }}
                        >
                            <CustomTranslation value={'KEY.Save'} />
                        </Button>
                    }
                >
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{ position: 'sticky', top: '2rem', zIndex: 9 }}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>
                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id })}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:construction_site_id/assignments', { construction_site_id: this.state.data.constructionSite.id })}
                                >
                                    <CustomTranslation value={'KEY.Assignment actions'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Assignment'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div className={'vertical-margin'}>
                                <h1><CustomTranslation value={'KEY.Edit assignment'} /></h1>
                                <p>
                                    {this.state.data.assignment.message &&
                                        <CustomTranslation value={'KEY.Risk analysis message'} />
                                    }
                                    {this.state.data.assignment.lastMinuteRiskAnalysisItem &&
                                        <CustomTranslation value={'KEY.LMRA'} />
                                    }
                                    {this.state.data.assignment.observation && !(this.state.data.assignment.observation.positiveRemark) &&
                                        <CustomTranslation value={'KEY.Observation'} />
                                    }
                                    {this.state.data.assignment.observation && this.state.data.assignment.observation.positiveRemark &&
                                        <CustomTranslation value={'KEY.Positive Remark'} />
                                    }
                                    {this.state.data.assignment.taskRiskAnalysisItem &&
                                        <CustomTranslation value={'KEY.Risk analysis item'} />
                                    }
                                    {this.state.data.assignment.inspectionChecklistItem && !(this.state.data.assignment.inspectionChecklistItem.positiveRemark) &&
                                        <CustomTranslation value={'KEY.Inspection checklist item'} />
                                    }
                                    {this.state.data.assignment.toolbox &&
                                        <CustomTranslation value={'KEY.Toolbox'} />
                                    }
                                </p>
                            </div>

                            {/* Form */}
                            <div>
                                {this.state.data.assignment.toolbox &&
                                    <div className={'vertical-padding-3'}>
                                        <CustomFileInput
                                            iconName={'las la-file-alt'}
                                            label={<CustomTranslation value={'KEY.Document detail'} />}
                                            labelAppend={this.state.concept ? '' : '*'}
                                            mimeType={() =>
                                                [
                                                    "application/pdf",
                                                    "application/msword",
                                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                    "application/vnd.oasis.opendocument.text",
                                                    "application/vnd.apple.pages",
                                                    "application/x-iwork-pages-sffpages",
                                                    "application/vnd.ms-powerpoint",
                                                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                                    "application/vnd.oasis.opendocument.presentation",
                                                    "application/vnd.apple.keynote",
                                                    "application/x-iwork-keynote-sffkey",
                                                    "application/vnd.ms-excel",
                                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                    "application/vnd.oasis.opendocument.spreadsheet",
                                                    "application/vnd.apple.numbers",
                                                    "application/x-iwork-numbers-sffnumbers"
                                                ].join(',')
                                            }
                                            btnLabel={<CustomTranslation value={'KEY.Upload document'} />}
                                            onUpload={(val) => this.handleUpload(val, 'document')}
                                            fileId={this.state.documentId ? this.state.documentId : null}
                                        />
                                    </div>
                                }
                                {!this.state.data.assignment.toolbox &&
                                    <>
                                        <div className={'vertical-padding-3'}>
                                            <CustomImageInput
                                                iconName={'las la-image'}
                                                label={<CustomTranslation value={'KEY.Image overview'} />}
                                                onUpload={(val) => this.handleUpload(val, 'image')}
                                                imageId={this.state.imageId ? this.state.imageId : null}
                                            />
                                        </div>
                                        <div className={'vertical-padding-3'}>
                                            <CustomImageInput
                                                iconName={'las la-image'}
                                                label={<CustomTranslation value={'KEY.Image detail'} />}
                                                onUpload={(val) => this.handleUpload(val, 'image2')}
                                                imageId={this.state.image2Id ? this.state.image2Id : null}
                                            />
                                        </div>
                                    </>
                                }
                                <div className={'vertical-padding-3'}>
                                    <CustomInput
                                        iconName={'las la-quote-left'}
                                        label={<CustomTranslation value={'KEY.Title'} />}
                                        labelAppend={this.state.concept ? '' : '*'}
                                        value={this.state.title}
                                        onChangeText={(text) => this.setState({ title: text })}
                                    />
                                </div>
                                {!this.state.data.assignment.toolbox &&
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            iconName={'las la-quote-left'}
                                            label={
                                                (this.state.data.assignment.observation && this.state.data.assignment.positiveRemark) ?
                                                    <CustomTranslation value={'KEY.Positive find'} /> :
                                                    <CustomTranslation value={'KEY.Description'} />
                                            }
                                            labelAppend={this.state.concept ? '' : '*'}
                                            value={this.state.riskDescription}
                                            onChangeText={(text) => this.setState({ riskDescription: text })}
                                        />
                                    </div>
                                }
                                {(this.state.data.assignment.observation && this.state.data.assignment.observation.positiveRemark) ?
                                    null :
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            iconName={'las la-quote-left'}
                                            label={this.state.data.assignment.toolbox ?
                                                <CustomTranslation value={'KEY.Optional extra information'} /> :
                                                <CustomTranslation value={'KEY.Actions taken (by me)'} />
                                            }
                                            labelAppend={this.state.concept || (this.state.data.assignment.toolbox) ? '' : '*'}
                                            inputProps={{
                                                as: 'textarea'
                                            }}
                                            value={this.state.immediateActionTaken}
                                            onChangeText={(text) => this.setState({ immediateActionTaken: text })}
                                        />
                                    </div>
                                }
                                {!this.state.data.assignment.toolbox &&
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            iconName={'las la-quote-left'}
                                            label={
                                                (this.state.data.assignment.observation && this.state.data.assignment.positiveRemark) ?
                                                    <CustomTranslation value={'KEY.Possible Recommendation'} /> :
                                                    <CustomTranslation value={'KEY.Recommendation'} />
                                            }
                                            labelAppend={this.state.concept || (this.state.data.assignment.observation && this.state.data.assignment.positiveRemark) ? '' : '*'}
                                            inputProps={{
                                                as: 'textarea'
                                            }}
                                            value={this.state.recommendation}
                                            onChangeText={(text) => this.setState({ recommendation: text })}
                                        />
                                    </div>
                                }
                                <div className={'vertical-padding-3'}>
                                    <CustomDate
                                        iconName={'las la-calendar'}
                                        label={this.state.data.assignment.toolbox ?
                                            <CustomTranslation value={'KEY.Toolbox ready by'} /> :
                                            <CustomTranslation value={'KEY.Solve by'} />
                                        }
                                        labelAppend={this.state.concept || (this.state.data.assignment.observation && this.state.data.assignment.positiveRemark) ? '' : '*'}
                                        value={this.state.dateRequested}
                                        onChange={(value) => this.setState({ dateRequested: value })}
                                    />
                                </div>
                                {(this.state.data.assignment.observation && this.state.data.assignment.observation.positiveRemark) ?
                                    null :
                                    <div className={'vertical-padding-3'}>
                                        <CustomCheckBox
                                            label={<CustomTranslation value={'KEY.Finished'} />}
                                            value={this.state.finished}
                                            onChange={(value) => this.setState({ finished: value })}
                                        />
                                    </div>
                                }
                                <div className={'vertical-padding-3'}>
                                    <CustomSelect
                                        iconName={'las la-user'}
                                        label={
                                            (this.state.data.assignment.observation && this.state.data.assignment.positiveRemark) ?
                                                <CustomTranslation value={'KEY.Responsible recommendation'} /> :
                                                <CustomTranslation value={'KEY.Assign to'} />
                                        }
                                        labelAppend={this.state.concept || (this.state.data.assignment.observation && this.state.data.assignment.positiveRemark) ? '' : '*'}
                                        options={this.state.userOptions}
                                        selected={this.state.user}
                                        onSelect={(value, label) => {
                                            this.setState({ targetUser: { id: value } });
                                            this.setState({ user: { label: label, value: value } });
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomCheckBox
                                        label={<CustomTranslation value={'KEY.Save as concept'} />}
                                        onValueChange={(value) => this.setState({ concept: value })}
                                        value={this.state.concept}
                                    />
                                </div>
                                {this.state.data.assignment.toolbox &&
                                    <>
                                        {((this.state.data.assignment.toolbox.signatures && this.state.data.assignment.toolbox.signatures.length > 0) || (this.state.data.assignment.toolbox.participants && this.state.data.assignment.toolbox.participants.length === 0)) &&
                                            <div className={'vertical-padding-3'}>
                                                <ToolboxParticipantPicker
                                                    company={this.state.data.constructionSite.company}
                                                    label={<CustomTranslation value={'KEY.Participants'} />}
                                                    labelAppend={this.state.concept ? '' : '*'}
                                                    iconName={'las la-users'}
                                                    title={<CustomTranslation value={'KEY.Select Participants'} />}
                                                    onValueChange={(value) => {
                                                        this.setState({ selectedParticipants: value });
                                                    }}
                                                    value={this.state.selectedParticipants}
                                                />
                                            </div>
                                        }
                                        {this.state.data.assignment.toolbox.participants.length > 0 &&
                                            <div className={'vertical-padding-3'}>
                                                <CustomListInput
                                                    unique={true}
                                                    label={<CustomTranslation value={'KEY.Custom Participants'} />}
                                                    labelAppend={this.state.concept ? '' : '*'}
                                                    iconName={'las la-users'}
                                                    onChangeText={(value) => {
                                                        let output = value;
                                                        if (typeof value === 'object') {
                                                            output = output.filter(n => {
                                                                return n !== '';
                                                            });
                                                        }
                                                        this.setState({ participants: output });
                                                    }}
                                                    value={this.state.participants}
                                                />
                                            </div>
                                        }
                                    </>
                                }
                                {this.state.data.assignment.toolbox &&
                                    <div className={'vertical-padding-3'}>
                                        <CustomImageInput
                                            iconName={'las la-image'}
                                            label={<CustomTranslation value={'KEY.Instruction/Toolboxmoment'} />}
                                            labelAppend={this.state.concept ? '' : ''}
                                            onUpload={(val) => this.handleUpload(val, 'image2')}
                                            imageId={this.state.image2Id ? this.state.image2Id : null}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <EditAssignmentItemScreen {...props} params={params} />;
}
