import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import UserAction from "../../Actions/UserAction";
import {Breadcrumb, Nav, NavItem, Tab} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import PersonalPreferencesTab from "./Personal/PersonalPreferencesTab";
import CompanyAction from "../../Actions/CompanyAction";
import CompanyPreferencesTab from "./Company/CompanyPreferencesTab";
import CompanyLicenseOverviewTab from "./Company/CompanyLicenseOverviewTab";
import CompanyUserRightTab from "./Company/CompanyUserRightTab";
import CompanyStructureTab from "./Company/CompanyStructureTab";
import PasswordPreferencesTab from "./Password/PasswordPreferencesTab";
import LicenseAction from "../../Actions/LicenseAction";
import LMRAPreferencesTab from "./LMRA/LMRAPreferencesTab";
import ICLPreferencesTab from "./ICL/ICLPreferencesTab";
import RiskAnalysisPreferencesTab from "./RiskAnalysis/RiskAnalysisPreferencesTab";
import ToolboxPreferencesTab from "./Toolbox/ToolboxPreferencesTab";
import AnalyticsLog from "../../Firebase/AnalyticsLog";

class PreferencesScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        //get current user
        let currentUser = await UserAction.getCurrentUser();

        //get default company
        let defaultCompany = await CompanyAction.getCompany(currentUser.defaultCompany.id);

        //get rights
        let userCompanyRights = await UserAction.getUserRightByCompanyId(defaultCompany.id);

        //get licenses
        let licenses = await LicenseAction.getLicensesByUserAndCompany(defaultCompany.id);

        //set state
        this.setState({
            data: {
                currentUser: currentUser,
                defaultCompany: defaultCompany,
            },
            userCompanyRights: userCompanyRights,
            licenses: licenses
        });
    }

    render = () => {

        if (this.state.data) {
            return (
                <CustomContainer>
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'}/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.Preferences'}/>
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div>
                            <h1>
                                <CustomTranslation value={'KEY.Preferences'}/>
                            </h1>
                        </div>

                        {/* Tabs container */}
                        <div>
                            <Tab.Container defaultActiveKey={'personal'} mountOnEnter={true} unmountOnExit={false}>
                                {/* Flex container */}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <div
                                        className={'standard-margin vertical-margin-2'}
                                        style={{
                                            marginLeft: 0,
                                            marginTop: 0,
                                        }}
                                    >
                                        {/* General settings */}
                                        <div className={'bordered rounded vertical-margin-2'} style={{marginTop: 0}}>
                                            <Nav variant={'pills'} className={'flex-column'}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={'personal'}>
                                                        <i className={'las la-user'} /> <CustomTranslation value={'KEY.Personal'} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {(this.state.licenses && this.state.licenses.length > 0) && (this.state.userCompanyRights.managementRights || this.state.userCompanyRights.adminRights) &&
                                                    <NavItem>
                                                        <Nav.Link eventKey={'company'}>
                                                            <i className={'las la-building'}/> <CustomTranslation
                                                            value={'KEY.Company'}/>
                                                        </Nav.Link>
                                                    </NavItem>
                                                }
                                                {(this.state.licenses && this.state.licenses.length > 0) && (this.state.userCompanyRights.adminRights) &&
                                                    <NavItem>
                                                        <Nav.Link eventKey={'license'}>
                                                            <i className={'las la-certificate'}/> <CustomTranslation
                                                            value={'KEY.Company Licenses'}/>
                                                        </Nav.Link>
                                                    </NavItem>
                                                }
                                                {(this.state.licenses && this.state.licenses.length > 0) && (this.state.userCompanyRights.managementRights || this.state.userCompanyRights.adminRights) &&
                                                    <NavItem>
                                                        <Nav.Link eventKey={'users'}>
                                                            <i className={'las la-users'}/> <CustomTranslation
                                                            value={'KEY.Company Users'}/>
                                                        </Nav.Link>
                                                    </NavItem>
                                                }
                                                {(this.state.licenses && this.state.licenses.length > 0) && (this.state.userCompanyRights.managementRights || this.state.userCompanyRights.adminRights) &&
                                                    <NavItem>
                                                        <Nav.Link eventKey={'structure'}>
                                                            <i className={'las la-sitemap'}/> <CustomTranslation
                                                            value={'KEY.Company Structure'}/>
                                                        </Nav.Link>
                                                    </NavItem>
                                                }
                                                <NavItem>
                                                    <Nav.Link eventKey={'password'}>
                                                        <i className={'las la-lock'} /> <CustomTranslation value={'KEY.Password'} />
                                                    </Nav.Link>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        {/* Template editing */}
                                        {(this.state.licenses && this.state.licenses.length > 0) && (this.state.userCompanyRights.adminRights) &&
                                            <div className={'bordered rounded'}>
                                                <Nav variant={'pills'} className={'flex-column'}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={'lmra'}>
                                                            <i className={'las la-industry'}/> <CustomTranslation
                                                            value={'KEY.Last Minute Risk Analysis Template'}/>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <NavItem>
                                                        <Nav.Link eventKey={'icl'}>
                                                            <i className={'las la-industry'}/> <CustomTranslation
                                                            value={'KEY.Inspection Checklist Template'}/>
                                                        </Nav.Link>
                                                    </NavItem>
                                                    <NavItem>
                                                        <Nav.Link eventKey={'ra'}>
                                                            <i className={'las la-file'}/> <CustomTranslation
                                                            value={'KEY.Risk Analysis Template'}/>
                                                        </Nav.Link>
                                                    </NavItem>
                                                    <NavItem>
                                                        <Nav.Link eventKey={'toolbox'}>
                                                            <i className={'las la-briefcase'}/> <CustomTranslation
                                                            value={'KEY.Toolbox Participants'}/>
                                                        </Nav.Link>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        }
                                    </div>
                                    {/* Content container */}
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            flexShrink: 1
                                        }}
                                    >
                                        <Tab.Content>
                                            <Tab.Pane eventKey={'personal'}>
                                                <AnalyticsLog screen={'PersonalPreferencesScreen'} />
                                                <PersonalPreferencesTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'company'}>
                                                <AnalyticsLog screen={'CompanyPreferencesScreen'} />
                                                <CompanyPreferencesTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'license'}>
                                                <AnalyticsLog screen={'CompanyLicenseOverviewScreen'} />
                                                <CompanyLicenseOverviewTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'users'}>
                                                <AnalyticsLog screen={'CompanyUserRightScreen'} />
                                                <CompanyUserRightTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'structure'}>
                                                <AnalyticsLog screen={'CompanyStructureScreen'} />
                                                <CompanyStructureTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'password'}>
                                                <AnalyticsLog screen={'PasswordPreferencesScreen'} />
                                                <PasswordPreferencesTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'lmra'}>
                                                <AnalyticsLog screen={'LMRAPreferencesScreen'} />
                                                <LMRAPreferencesTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'icl'}>
                                                <AnalyticsLog screen={'ICLPreferencesScreen'} />
                                                <ICLPreferencesTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'ra'}>
                                                <AnalyticsLog screen={'RiskAnalysisPreferencesScreen'} />
                                                <RiskAnalysisPreferencesTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={'toolbox'}>
                                                <AnalyticsLog screen={'ToolboxPreferencesScreen'} />
                                                <ToolboxPreferencesTab user={this.state.data.currentUser} defaultCompany={this.state.data.defaultCompany} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <PreferencesScreen {...props} params={params} />;
}