import APIAction from "./APIAction";
import fileDownload from "js-file-download";

//define class
let ToolboxAction = {
    //create toolbox based on input
    createToolbox: async function (data) {
        //call api
        let result = await APIAction.request('/toolboxes', 'POST', true, data);

        //return
        return result;
    },

    //update toolbox
    updateToolbox: async function (data) {
        //call api
        let result = await APIAction.request('/toolboxes/' + data.id, 'PATCH', true, data);

        //return
        return result;
    },

    //list all toolboxes
    list: async function (constructionSiteId) {
        //call api
        let result = await APIAction.request('/toolboxes', 'GET', true, {
            'construction_site_id': constructionSiteId
        });

        //return
        return result;
    },

    //get toolbox
    getToolbox: async function (id) {
        //call api
        let result = await APIAction.request('/toolboxes/' + id, 'GET', true);

        //return
        return result;
    },

    //delete toolbox
    deleteToolbox: async function (toolboxId) {
        //call api
        let result = await APIAction.request('/toolboxes/' + toolboxId, 'PATCH', true, { active: false })

        //return
        return result;
    },

    //get participants
    getParticipants: async function (companyId) {
        //call api
        let result = await APIAction.request('/toolbox_participants', 'GET', true, {
            'company_id': companyId
        });

        //return
        return result;
    },

    //create participant
    createParticipant: async function (data) {
        //call api
        let result = await APIAction.request('/toolbox_participants', 'POST', true, data);

        //return
        return result;
    },

    //delete participant
    deleteParticipant: async function (id) {
        //call api
        let result = await APIAction.request('/toolbox_participants/' + id, 'PATCH', true, { active: false });

        //return
        return result;
    },

    //edit participant
    editParticipant: async function (id, data) {
        //call api
        let result = await APIAction.request('/toolbox_participants/' + id, 'PATCH', true, data);

        //return
        return result;
    },

    //attach signature
    attachSignature: async function (signatureId, uploadIri, firstName, lastName) {
        //call api
        let result = await APIAction.request('/toolbox_participant_signatures/' + signatureId, 'PATCH', true, {
            signature: uploadIri,
            firstName: firstName,
            lastName: lastName
        });

        //return
        return result;
    },

    //upload participants
    uploadParticipants: async function (file) {
        //create form data
        let formData = new FormData();
        formData.append('file', file, file.name);

        //call api
        let result = await APIAction.request('/toolbox/upload_participants', 'POST', true, formData, true, false, 'formdata');

        //return
        return result;
    },

    //download participants
    downloadParticipants: async function () {

        //default file name
        let fileName = 'participants.xlsx';

        let result = await APIAction.request('/toolbox/download_participants', 'GET', true, null, true, false, 'json', true);

        //download file
        fileDownload(result, fileName);
    },

    //get toolbox
    getToolbox: async function (id) {
        //call api
        let result = await APIAction.request('/toolboxes/' + id, 'GET', true);

        //return
        return result;
    },

};

//export
export default ToolboxAction;
