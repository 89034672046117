import React from "react";
import CustomTranslation from "../../../Components/CustomTranslation";
import { Button } from "react-bootstrap";
import ToolboxAction from "../../../../Actions/ToolboxAction";
import { toast } from "react-hot-toast";
import CustomConfirm from "../../../Components/CustomConfirm";
import ToolboxParticipantEditModal from "./ToolboxParticipantEditModal";
import Uuid from "uuid/v1";

class ToolboxParticipant extends React.Component {

    constructor(props) {
        super(props);

        //set state
        this.state = {
            showRemoveModal: false,
            showEditModal: false,
            editModalKey: Uuid()
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

    }

    //delete participant
    deleteParticipant = async () => {
        let participant = await ToolboxAction.deleteParticipant(this.props.data.id);

        if (participant) {
            toast.success(<CustomTranslation value={'KEY.Participant removed'} />);
        } else {
            toast.error(<CustomTranslation value={'KEY.Participant could not be removed'} />);
        }

        if (typeof this.props.onSubmitted === 'function') {
            this.props.onSubmitted(participant);
        }

        return participant;
    }

    //edit participant
    editParticipant = async (firstName, lastName) => {
        let data = {
            firstName: firstName,
            lastName: lastName
        };

        let participant = await ToolboxAction.editParticipant(this.props.data.id, data);

        if (participant) {
            toast.success(<CustomTranslation value={'KEY.Participant updated'} />);
        } else {
            toast.error(<CustomTranslation value={'KEY.Participant could not be updated'} />);
        }

        if (typeof this.props.onSubmitted === 'function') {
            this.props.onSubmitted(participant);
        }

        return participant;
    }

    render = () => {
        return (
            <>
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    <div
                        className={'bordered rounded vertical-padding-2 standard-padding-2 vertical-margin-2'}
                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
                    >
                        <h3
                            style={{
                                wordBreak: 'break-word'
                            }}
                        >
                            {this.props.data.firstName} {this.props.data.lastName}
                        </h3>
                        <div
                            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
                        >
                            <Button
                                size={'sm'}
                                variant={'warning'}
                                style={{height: '1.5rem', width: '1.5rem', paddingTop: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                                className={'standard-margin-2'}
                                onClick={() => {
                                    this.setState({
                                        showEditModal: true
                                    });
                                }}
                            >
                                <span>
                                    <i style={{ fontSize: '1rem' }} className={'las la-pen text-white'} />
                                </span>
                            </Button>
                            <Button
                                size={'sm'}
                                variant={'danger'}
                                style={{ height: '1.5rem', width: '1.5rem', paddingTop: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        showRemoveModal: true
                                    });
                                }}
                            >
                                <span>
                                    <i style={{ fontSize: '1rem' }} className={'las la-trash text-white'} />
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
                <CustomConfirm
                    show={this.state.showRemoveModal}
                    title={<CustomTranslation value={'KEY.Delete Participant'} />}
                    cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                    onCancel={() => {
                        this.setState({
                            showRemoveModal: false
                        });
                    }}
                    confirmLabel={<CustomTranslation value={'KEY.Remove'} />}
                    variant={'danger'}
                    onConfirm={() => {
                        this.deleteParticipant().then(() => {
                            toast.success(<CustomTranslation value={'Participant removed'} />);
                        });
                        this.setState({
                            showRemoveModal: false
                        });
                    }}
                    body={
                        <p style={{margin: 0}}>
                            <CustomTranslation value="KEY.Are you sure you want to remove:" />
                            {' '}
                            <span className={"font-weight-bold"}>{this.props.data.firstName} {this.props.data.lastName}</span>?
                        </p>
                    }
                />
                <ToolboxParticipantEditModal
                    key={this.state.editModalKey}
                    title={<CustomTranslation value={'KEY.Edit Participant'} />}
                    show={this.state.showEditModal}
                    onClose={() => {
                        this.setState({
                            showEditModal: false,
                            editModalKey: Uuid()
                        });
                    }}
                    onConfirm={({firstName, lastName}) => {
                        this.editParticipant(firstName, lastName).then(() => {
                            this.setState({
                                showEditModal: false
                            });
                        });
                    }}
                    confirmLabel={<CustomTranslation value={'KEY.Save'} />}
                    firstName={this.props.data.firstName}
                    lastName={this.props.data.lastName}
                />
            </>
        );
    };
}

export default ToolboxParticipant;